const citiesData = {
    Ahtubinsk: {
        urlName: "Ahtubinsk",
        url: "/main/api",
        name: "Ахтубинск",
        appQR: '/assets/city-qr/Ahtubinsk.png',
        visible: true,
        social: {
            iosurl: "https://apps.apple.com/us/app/sunpanda/id1556527898",
            androidurl: "https://play.google.com/store/apps/details?id=com.sunpanda.android",
            Ok: "https://ok.ru/profile/583908776890",
            Vk: !1,
            VkUrl: "https://vk.com/id734761040",
            Rutube: !1,
            RutubeUrl: "https://rutube.ru/channel/27161304/",
            Tg: !1,
            TgUrl: "https://t.me/sunpanda_ahtubinsk"
        }
    },
    Volgodonsk: {
        urlName: "Volgodonsk",
        url: "/Volgodonsk/api",
        name: "Волгодонск",
        appQR: '/assets/city-qr/playstoreqr.svg',
        visible: true,
        social: {}
    },
    Eysk: {
        urlName: "Eysk",
        url: "/Eysk/api",
        name: "Ейск",
        appQR: '/assets/city-qr/Eisk.png',
        visible: true,
        social: {}
    },
    Znamensk: {
        urlName: "Znamensk",
        url: "/Znamensk/api",
        name: "Знаменск",
        appQR: '/assets/city-qr/Znamensk.png',
        visible: false,
        social: {}
    },
    KamenskShahtinsk: {
        urlName: "KamenskShahtinsk",
        url: "/KamenskShahtinsk/api",
        name: "Каменск-Шахтинский",
        appQR: '/assets/city-qr/kamensk.png',
        visible: false,
        social: {
            iosurl: "https://apps.apple.com/ru/app/sunpanda-%D0%BA%D0%B0%D0%BC%D0%B5%D0%BD%D1%81%D0%BA-%D1%88%D0%B0%D1%85%D1%82%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%D0%B9/id1631524348",
            androidurl: "https://play.google.com/store/apps/details?id=com.sunpanda.android.kamensk",
            Ok: "https://ok.ru/profile/587573226445",
            Vk: !0,
            VkUrl: "https://vk.com/id738223839",
            Rutube: !0,
            RutubeUrl: "https://rutube.ru/channel/27161304/"
        }
    },
    Krymsk: {
        urlName: "Krymsk",
        url: "/Krymsk/api",
        name: "Крымск",
        appQR: '/assets/city-qr/Krimsk.jpg',
        visible: true,
        social: {
            iosurl: "https://apps.apple.com/ru/app/sunpanda-%D0%BA%D1%80%D1%8B%D0%BC%D1%81%D0%BA/id1631562343",
            androidurl: "https://play.google.com/store/apps/details?id=com.sunpanda.android.krymsk",
            Ok: "https://ok.ru/profile/584766020818",
            Tg: !0,
            TgUrl: "https://t.me/sunpanda_krymsk"
        }
    },
    Novoshahtinsk: {
        urlName: "Novoshahtinsk",
        url: "/Novoshahtinsk/api",
        name: "Новошахтинск",
        appQR: '/assets/city-qr/Novoshahtinsk.png',
        visible: true,
        social: {
            iosurl: "https://apps.apple.com/ru/app/sunpanda-%D0%BD%D0%BE%D0%B2%D0%BE%D1%88%D0%B0%D1%85%D1%82%D0%B8%D0%BD%D1%81%D0%BA/id1627197023",
            androidurl: "https://play.google.com/store/apps/details?id=com.sunpanda.android.nshah",
            Ok: "https://ok.ru/profile/595766340099",
            Vk: !0,
            VkUrl: "https://vk.com/id734761040"
        }
    },
    Sarapul: {
        urlName: "Sarapul",
        url: "/Sarapul/api",
        name: "Сарапул",
        appQR: '/assets/city-qr/sarapul.png',
        visible: true,
        social: {
            iosurl: "https://apps.apple.com/ru/app/sunpanda-%D1%81%D0%B0%D1%80%D0%B0%D0%BF%D1%83%D0%BB/id6443677560",
            androidurl: "https://play.google.com/store/apps/details?id=com.sunpanda.android.sarapul",
            Ok: "https://ok.ru/profile/596586191367",
            Vk: !0,
            VkUrl: "https://vk.com/id754930777"
        }
    },
    UlanUde: {
        urlName: "UlanUde",
        url: "/UlanUde/api",
        name: "Sushi Land",
        appQR: '/assets/city-qr/Znamensk.png',
        visible: false,
        social: {}
    },
};

export const getCityData = (cityName) => {
    return citiesData[cityName] || null;
};

export const getAllCities = () => {
    return citiesData;
};
